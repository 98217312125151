var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Member number'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 1288',"disabled":_vm.isAction == 'edit' ? true : false},model:{value:(_vm.objCrm.member_number),callback:function ($$v) {_vm.$set(_vm.objCrm, "member_number", $$v)},expression:"objCrm.member_number"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Customer name'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' YukiSushi S.A.'},model:{value:(_vm.objCrm.client_name),callback:function ($$v) {_vm.$set(_vm.objCrm, "client_name", $$v)},expression:"objCrm.client_name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":_vm.isAction != 'addDependent' && _vm.isAction != 'edit-dependent'
                ? 'required'
                : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Customer email'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.isAction != 'addDependent' ? '' : _vm.$t('This field is optional')}},[_c('b-input',{attrs:{"type":"email","placeholder":_vm.$t('Ex:') + ' @gmail.com'},model:{value:(_vm.objCrm.client_email),callback:function ($$v) {_vm.$set(_vm.objCrm, "client_email", $$v)},expression:"objCrm.client_email"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Alternate email'),"message":_vm.$t('This field is optional'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"type":"email","placeholder":_vm.$t('Ex:') + ' alternative@gmail.com'},model:{value:(_vm.objCrm.client_email_alternative),callback:function ($$v) {_vm.$set(_vm.objCrm, "client_email_alternative", $$v)},expression:"objCrm.client_email_alternative"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Type of identification'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objCrm.client_type_identification),callback:function ($$v) {_vm.$set(_vm.objCrm, "client_type_identification", $$v)},expression:"objCrm.client_type_identification"}},_vm._l((_vm.typeIdentification),function(option){return _c('option',{key:option.id,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Type of residence'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objCrm.type_residency),callback:function ($$v) {_vm.$set(_vm.objCrm, "type_residency", $$v)},expression:"objCrm.type_residency"}},_vm._l((_vm.typeResidency),function(option){return _c('option',{key:option.id,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Customer ID'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 31111811411'},model:{value:(_vm.objCrm.client_identification),callback:function ($$v) {_vm.$set(_vm.objCrm, "client_identification", $$v)},expression:"objCrm.client_identification"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Date of birth'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objCrm.birth_date),callback:function ($$v) {_vm.$set(_vm.objCrm, "birth_date", $$v)},expression:"objCrm.birth_date"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Expiration date ID'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objCrm.date_expire_identification),callback:function ($$v) {_vm.$set(_vm.objCrm, "date_expire_identification", $$v)},expression:"objCrm.date_expire_identification"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Passport'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 11111811411'},model:{value:(_vm.objCrm.passport),callback:function ($$v) {_vm.$set(_vm.objCrm, "passport", $$v)},expression:"objCrm.passport"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Telephone'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 8888-8888',"type":"number","min":"8","max":"16"},model:{value:(_vm.objCrm.phone),callback:function ($$v) {_vm.$set(_vm.objCrm, "phone", $$v)},expression:"objCrm.phone"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Home phone'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 8888-8888',"type":"number","min":"8","max":"16"},model:{value:(_vm.objCrm.house_phone),callback:function ($$v) {_vm.$set(_vm.objCrm, "house_phone", $$v)},expression:"objCrm.house_phone"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Work phone'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 8888-8888',"type":"number","min":"8","max":"16"},model:{value:(_vm.objCrm.work_phone),callback:function ($$v) {_vm.$set(_vm.objCrm, "work_phone", $$v)},expression:"objCrm.work_phone"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Province'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' San José'},model:{value:(_vm.objCrm.province),callback:function ($$v) {_vm.$set(_vm.objCrm, "province", $$v)},expression:"objCrm.province"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Canton'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' Escazú'},model:{value:(_vm.objCrm.canton),callback:function ($$v) {_vm.$set(_vm.objCrm, "canton", $$v)},expression:"objCrm.canton"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('District'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' Bello horizonte'},model:{value:(_vm.objCrm.district),callback:function ($$v) {_vm.$set(_vm.objCrm, "district", $$v)},expression:"objCrm.district"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Date of approval'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objCrm.date_approbation),callback:function ($$v) {_vm.$set(_vm.objCrm, "date_approbation", $$v)},expression:"objCrm.date_approbation"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Membership start date'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today","date-parser":Date.parse},model:{value:(_vm.objCrm.date_start_membership),callback:function ($$v) {_vm.$set(_vm.objCrm, "date_start_membership", $$v)},expression:"objCrm.date_start_membership"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Membership expiration date'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objCrm.date_expire_membership),callback:function ($$v) {_vm.$set(_vm.objCrm, "date_expire_membership", $$v)},expression:"objCrm.date_expire_membership"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Membership fee'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 00.01',"type":"number"},model:{value:(_vm.objCrm.membership_cost),callback:function ($$v) {_vm.$set(_vm.objCrm, "membership_cost", $$v)},expression:"objCrm.membership_cost"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Address'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","placeholder":_vm.$t('Ex:') + ' De la escuela...calle #12'},model:{value:(_vm.objCrm.address),callback:function ($$v) {_vm.$set(_vm.objCrm, "address", $$v)},expression:"objCrm.address"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Remarks'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","placeholder":_vm.$t('Ex:') + '  pago de servicios...'},model:{value:(_vm.objCrm.observation),callback:function ($$v) {_vm.$set(_vm.objCrm, "observation", $$v)},expression:"objCrm.observation"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('State'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objCrm.migratory_status),callback:function ($$v) {_vm.$set(_vm.objCrm, "migratory_status", $$v)},expression:"objCrm.migratory_status"}},_vm._l((_vm.migratoryStatus),function(option){return _c('option',{key:option.id,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"is-flex is-flex-direction-column pt-3"},[_c('label',{staticClass:"label pr-3 is-align-self-flex-start",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Document')))]),_c('b-field',{staticClass:"file",class:{ 'has-name': !!_vm.objCrm.upload }},[_c('b-upload',{staticClass:"file-label",on:{"input":function($event){return _vm.fileChange()}},model:{value:(_vm.objCrm.upload),callback:function ($$v) {_vm.$set(_vm.objCrm, "upload", $$v)},expression:"objCrm.upload"}},[_c('span',{staticClass:"file-cta"},[_c('b-icon',{staticClass:"file-icon",attrs:{"icon":"upload"}}),_c('span',{staticClass:"file-label"},[_vm._v("Subir documento")])],1),(_vm.objCrm.upload)?_c('span',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm.objCrm.upload.name)+" ")]):_vm._e()])],1)],1)])])]),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"button btn-save",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"button btn-cancel",on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }