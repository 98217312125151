import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Crm from '@/Models/Crm'
import httpMulti from '@/services/http'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormCrm extends Vue {
  @Prop({ required: false, default: null })
  objRow!: Crm
  @Prop({ required: false, default: null })
  isAction!: string
  //el mae seleccionado
  objCrm: Crm = new Crm()
  loading = false
  date: any = new Date()
  way!: string //ruta
  isFileEdit = false
  typeIdentification = [
    { id: 1, name: 'Física', value: 1 },
    { id: 2, name: 'Jurídica', value: 2 },
    { id: 3, name: 'NITE', value: 3 },
    { id: 4, name: 'DIMEX', value: 4 },
  ]
  typeResidency = [
    { id: 1, name: 'Pensionado', value: 1 },
    { id: 2, name: 'Permanente', value: 2 },
    { id: 3, name: 'Rentista', value: 3 },
    { id: 4, name: 'Inversionista', value: 4 },
    { id: 5, name: 'Vínculo', value: 5 },
    { id: 6, name: 'Naturalizado', value: 6 },
    { id: 7, name: 'Sin Asignar', value: 7 },
  ]
  migratoryStatus = [
    { id: 1, name: 'Activo', value: 'ACTIVO' },
    { id: 2, name: 'Inactivo', value: 'INACTIVO' },
  ]
  get is_login(): boolean {
    return authModule.isAuthenticated
  }
  mounted() {
    console.log(this.objRow)
    if (
      (this.objRow && this.isAction == 'edit') ||
      this.isAction == 'edit-dependent'
    ) {
      this.objCrm = this.objRow
    }
  }
  async confirmation() {
    this.loading = true
    const id = menuModule.id_param
    this.isAction == 'addDependent' && id
      ? (this.objCrm.parent_client = parseInt(id))
      : this.objCrm.parent_client
    const formData = this.crmForm(this.objCrm)
    switch (this.isAction) {
      case 'add':
        this.way = 'crm_client_file'
        await this.createCrm(formData)
        break
      case 'edit':
        await this.updateCrm(formData)
        break
      case 'addDependent':
        this.way = 'crm_client_file_dependent'
        await this.createCrm(formData)
        break
      case 'edit-dependent':
        await this.updateCrm(formData)
        break
      default:
        console.log('ups')
        break
    }
  }
  async createCrm(objCrm: any) {
    try {
      await httpMulti.post(`api/crm/${this.way}/`, objCrm)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      console.error(error.data)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async updateCrm(objCrm: any) {
    try {
      await httpMulti.put(`api/crm/crm_client_file/${this.objRow.id}/`, objCrm)
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      //console.error(error)
      this.loading = false
      switch (error.response.data.member_number[0]) {
        case 'Ya existe Crm Cliente Archivo con este member number.':
          this.$buefy.toast.open({
            message: `Ya exite un cliente con el mismo numero de miembro`,
            type: 'is-danger',
          })
          break
        default:
          this.$buefy.toast.open({
            message: `Ups!, algo salio mal`,
            type: 'is-danger',
          })
          break
      }
      /* this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: "is-danger",
      }) */
    }
  }

  crmForm(objCrm: Crm) {
    const formData = new FormData()
    const crm = {
      id: objCrm.id,
      member_number: objCrm.member_number,
      client_name: objCrm.client_name,
      client_email: objCrm.client_email,
      client_email_alternative: objCrm.client_email_alternative,
      client_type_identification: objCrm.client_type_identification,
      type_residency: objCrm.type_residency,
      client_identification: objCrm.client_identification,
      birth_date: Helpers.dateFormat_db(objCrm.birth_date),
      date_expire_identification: Helpers.dateFormat_db(
        objCrm.date_expire_identification,
      ),
      passport: objCrm.passport,
      phone: objCrm.phone,
      home_phone: objCrm.house_phone,
      work_phone: objCrm.work_phone,
      province: objCrm.province,
      canton: objCrm.canton,
      district: objCrm.district,
      date_approbation: Helpers.dateFormat_db(objCrm.date_approbation),
      date_start_membership: Helpers.dateFormat_db(
        objCrm.date_start_membership,
      ),
      date_expire_membership: Helpers.dateFormat_db(
        objCrm.date_expire_membership,
      ),
      membership_cost: objCrm.membership_cost,
      address: objCrm.address,
      observation: objCrm.observation,
      migratory_status: objCrm.migratory_status,
      parent_client: objCrm.parent_client,
    }
    formData.append('crm', JSON.stringify(crm))
    if (this.isFileEdit) {
      formData.append('file', objCrm.upload)
    }
    return formData
  }
  fileChange() {
    if (this.objCrm.upload.name) {
      this.isFileEdit = true
    }
  }
  close() {
    this.objCrm = new Crm()
    this.$emit('cleanForm')
  }
}

/*let keyObj = Object.keys(this.objCrm)
    let valuesObj = Object.keys(this.objCrm)

    for (let i = 0; i < keyObj.length ; i++) {
      formData.append(lengthObj[i], this.objCrm.file)

    } */
